import React from 'react'
import Experience from './Experience'
export default function Experiences() {
  return (
    <div className='main'>
        <h1 className='title'>Experiences</h1>
        <div className='experience_content row'>
            {experiences.map((experience) => (
                <Experience key={experience.id} src={experience.src} duration={experience.duration} details={experience.details} desc={experience.desc} name={experience.name} desc2={experience.desc2}/>
            ))}
        </div>
    </div>
  )
}
const experiences = [
    {
      id: 1,
      src: "https://coursereport-s3-production.global.ssl.fastly.net/uploads/school/logo/886/original/xr-20bootcamp-20logo.png",
      name: "XR Trainee",
      duration: "February 2024 - June 2024",
      desc: 'This highly selective five months Professional Unity VR Development Bootcamp trains job-ready software engineers.',
      details: [
        'Develop and prototype fps, VR, AR, MR, XR Games, and Applications',
        'Work well in XR Development Teams',
        'Use C# to program advanced functionality in Unity',
        'Use Rendering Optimization tools to optimize VR applications'
      ]
      ,
      desc2:"Together with industry partners, students develop advanced XR Prototypes alone and in teams. They learn to scope and pitch projects, report to clients, and manage their development times."
    },
    {
      id: 2,
      src: "https://media.licdn.com/dms/image/C4D0BAQEcBwsFpLCtog/company-logo_200_200/0/1642739253370?e=2147483647&v=beta&t=ppiD299mXAZelat5egQK9miDq5C1Ur1OfG0epc7g_eE",
      name: "3D/AR Software Engineer",
      duration: "June 2023 - March 2023",
      desc: 'I worked as a mobile AR software using unity at Earthmeta. Here are the tasks I performed:',
      details: [
        "Charged of coding the play to earn part of the Earthmeta mobile app",
        "Used trello for collaboration with backend team",
        "Ensured the quality and performance of the app",
        "Charged of implementing web requests like authentification",
        "Added some AR features",
        "Charged of implementing the UI of the app"
      ],
      desc2:""
    },
    {
      id: 3,
      src: "https://media.licdn.com/dms/image/C4E0BAQFAvA55uI9RQQ/company-logo_200_200/0/1630655510176/upwork_logo?e=2147483647&v=beta&t=9MjAV40m7NpNsh5lwVPO2tSUBAMs_0oQmET_y2cKP9Q",
      name: "Freelancer @Upwork",
      duration: "January 2024 - Now",
      desc: 'Successfully completed a Unity game development project for a client on Upwork.',
      details: [
        'Utilized Mapbox to integrate mapping functionality within the Unity environment.',
        'Delivered a high-quality output, resulting in a 5-star rating and positive client feedback.'
      ],
      desc2: ""
    }

]